<template>
	<div class="border pb-3 mb-5">
		<v-layout class="py-2 px-4 blue lighten-5">
			<v-flex class="">
				<div class="fw-600 sub-heading text-capitalize" style="color: #0d47a1">
					Google Search Console Credentials
				</div>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="d-flex justify-content-end">
				<v-btn
					class="white--text"
					dense
					tile
					color="red darken-4"
					@click="(GSCDialoag = true), (task = 'add')"
				>
					Add Credential
				</v-btn>
			</v-flex>
		</v-layout>

		<v-progress-linear v-if="pageLoading" indeterminate color="orange" height="8"></v-progress-linear>
		<v-layout :class="{ 'mt-2': !pageLoading }">
			<div class="v-data-table bt-table theme--light w-100">
				<div class="v-data-table__wrapper">
					<table class="" :id="'google-search-console-listing-table-' + (+new Date() + 2)">
						<thead>
							<tr>
								<th
									v-for="(header, idx) in GSC_listing_headers"
									:key="'creds_' + header.key + '_' + idx"
									:width="header.width"
								>
									{{ header.title }}
								</th>
							</tr>
						</thead>
						<tbody>
							<template v-if="GSC_list && GSC_list.length">
								<tr v-for="(creds, b_key) in GSC_list" :key="'$_body_' + b_key">
									<template v-for="(th, th_idx) in GSC_listing_headers">
										<td :key="'head_' + th_idx" :style="{ maxWidth: `${th.width}px !important` }">
											<!-- :class="{ 'cursor-pointer': th.key == 'action' }" -->
											<template v-if="th.key == 'srno'">
												<div class="fw-600 text-muted text-center">
													{{ (+page - 1) * 15 + b_key + 1 }}
												</div>
											</template>

											<template v-if="th.key == 'action'">
												<div class="text-center">
													<v-tooltip top>
														<template #activator="{ on, attrs }">
															<v-icon
																v-on="on"
																v-bind="attrs"
																size="20"
																class="cursor-pointer p-1 elevation-2"
																style="border: 1px solid; border-radius: 15%"
																@click="editGSCRecord(creds.id)"
																color="blue"
															>
																mdi-pencil
															</v-icon>
														</template>
														<span>Edit</span>
													</v-tooltip>
													<v-tooltip top v-if="!creds.active || creds.active == 0">
														<template #activator="{ on, attrs }">
															<v-btn
																x-small
																v-on="on"
																v-bind="attrs"
																color="blue darken-4"
																dark
																style="height: 28.5px"
																class="ml-2 px-0"
																@click="connectToGoogle(creds.id)"
															>
																<!-- <span style="font-size: 11px"> Connect </span> -->
																<v-icon>mdi-connection</v-icon>
															</v-btn>
														</template>
														<span>Connect</span>
													</v-tooltip>
													<v-tooltip top v-if="creds.active || creds.active == 1">
														<template #activator="{ on, attrs }">
															<v-btn
																v-on="on"
																v-bind="attrs"
																x-small
																color="red darken-4"
																dark
																style="height: 28.5px"
																class="ml-2 px-0"
															>
																<!-- <span style="font-size: 11px"> Disconnect </span> -->
																<v-icon>mdi-power-plug-off-outline</v-icon>
															</v-btn>
														</template>
														<span>Disconnect</span>
													</v-tooltip>
												</div>
											</template>

											<template v-if="th.key == 'email'">
												<div class="fw-500" style="color: #505050">
													<!-- {{ creds.email }} -->
													<table>
														<tr>
															<td class="fw-600 pl-0" style="border-right: none !important">Email</td>
															<td class="pl-0">
																<ShowValue
																	:object="creds"
																	objectKey="email"
																	:capitalize="false"
																	:click-to-copy="true"
																	label="Email"
																></ShowValue>
															</td>
														</tr>
														<tr>
															<td class="fw-600 pl-0" style="border-right: none !important">Service Email</td>
															<td class="pl-0">
																<ShowValue
																	:object="creds"
																	objectKey="service_email"
																	label="Service Email"
																	:click-to-copy="true"
																></ShowValue>
															</td>
														</tr>
													</table>
												</div>
											</template>

											<template v-if="th.key == 'status'">
												<div class="fw-500" style="color: #505050">
													<v-chip label v-if="creds.active || creds.active == 1" color="green" dark outlined
														>Active</v-chip
													>
													<v-chip label v-else color="red" dark outlined>Inactive</v-chip>
												</div>
											</template>

											<template v-if="th.key == 'ids'">
												<div class="fw-500" style="color: #505050">
													<table>
														<tr>
															<td class="fw-600 pl-0" style="border-right: none !important">Project ID</td>
															<td class="pl-0">
																<ShowValue
																	:object="creds"
																	objectKey="project_id"
																	:capitalize="false"
																	:click-to-copy="true"
																	label="Project ID"
																></ShowValue>
															</td>
														</tr>
														<tr>
															<td class="fw-600 pl-0" style="border-right: none !important">Client ID</td>
															<td class="pl-0">
																<ShowValue
																	:object="creds"
																	objectKey="client_id"
																	label="Client ID"
																	:click-to-copy="true"
																></ShowValue>
															</td>
														</tr>
													</table>
												</div>
											</template>
											<template v-if="th.key == 'client-secret'">
												<div class="fw-500 text-truncate" style="color: #505050">
													{{ creds.client_secret }}
												</div>
											</template>
											<!-- <template v-if="th.key == 'client-id'">
												<div class="fw-500 text-truncate" style="color: #505050">
													{{ creds.client_id }}
												</div>
											</template> -->
										</td>
									</template>
								</tr>
							</template>
							<template v-else>
								<tr>
									<td colspan="20">
										<p class="m-0 row-not-found text-center py-3">
											<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
											Uhh... There <span class="text-lowercase">are no projects at the moment.</span>
										</p>
									</td>
								</tr>
							</template>
						</tbody>
						<tfoot v-if="GSC_list.length == 0">
							<div class="text-center">No Credential</div>
						</tfoot>
					</table>
				</div>
			</div>
		</v-layout>

		<Dialog :dialog="GSCDialoag" :dialog-width="850" @close="closeDialog">
			<template #title> {{ task == "edit" ? "Edit" : "Add" }} GSC Credential</template>
			<template #body>
				<v-layout class="px-4 py-2">
					<v-form v-model="formValid" @submit="submitCredentials">
						<v-row>
							<v-col cols="12" lg="6" md="6">
								<label for="email" class="required">Email</label>
								<v-text-field
									outlined
									id="email"
									hide-details
									placeholder="example@gmail.com"
									v-model="GSC_record.email"
									:rules="[
										vrules.required(GSC_record.email, 'Email'),
										vrules.validEmail(GSC_record.email, 'Email'),
									]"
									:class="{
										required: !GSC_record.email,
									}"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="6" md="6">
								<label for="service_email" class="required">Service Email</label>
								<v-text-field
									outlined
									id="service_email"
									hide-details
									placeholder="example@gmail.com"
									v-model="GSC_record.service_email"
									:rules="[
										vrules.required(GSC_record.service_email, 'Service Email'),
										vrules.validEmail(GSC_record.service_email, 'Service Email'),
									]"
									:class="{
										required: !GSC_record.service_email,
									}"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="6" md="6">
								<label for="project_id" class="required">Project ID</label>
								<v-text-field
									outlined
									id="project_id"
									hide-details
									placeholder="qu********-**********122"
									v-model="GSC_record.project_id"
									:rules="[vrules.required(GSC_record.project_id, 'Project ID')]"
									:class="{
										required: !GSC_record.project_id,
									}"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="6" md="6">
								<label for="client_id" class="required">Client ID</label>
								<v-text-field
									outlined
									id="client_id"
									hide-details
									placeholder="110635282649518297071"
									v-model="GSC_record.client_id"
									:rules="[vrules.required(GSC_record.client_id, 'Client ID')]"
									:class="{
										required: !GSC_record.client_id,
									}"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="6" md="6">
								<label for="client_id" class="required">Client Secret</label>
								<v-text-field
									outlined
									id="client_id"
									hide-details
									placeholder="110635282649518297071"
									v-model="GSC_record.client_secret"
									:rules="[vrules.required(GSC_record.client_secret, 'Client Secret')]"
									:class="{
										required: !GSC_record.client_secret,
									}"
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-layout>
			</template>
			<template #action>
				<v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="pageLoading || !formValid"
					depressed
					color="blue darken-4"
					tile
					@click="submitCredentials"
				>
					Save
				</v-btn>
				<v-btn depressed tile @click="closeDialog">Close </v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { /* , PATCH, */ GET, POST } from "@/core/services/store/request.module";
/*import { SET_MESSAGE } from "@/core/services/store/common.module"; */
import ShowValue from "@/view/components/ShowValue";
import Dialog from "@/view/components/Dialog";

export default {
	name: "GoogleSearchConsoleCredentials",
	components: {
		Dialog,
		ShowValue,
	},
	data() {
		return {
			GSCDialoag: false,
			formValid: true,
			page: 1,
			GSC_record: {
				email: null,
				service_email: null,
				client_id: null,
				project_id: null,
				client_secret: null,
			},
			GSC_listing_headers: [
				{
					title: "Sr No.",
					key: "srno",
					// width: 60,
				},
				{
					title: "Action",
					key: "action",
					// width: 80,
				},
				{
					title: "Emails",
					key: "email",
					// width: 300,
				},
				{
					title: "Status",
					key: "status",
					// width: 300,
				},
				{
					title: "ID's",
					key: "ids",
					// width: 250,
				},
				{
					title: "Client Secret",
					key: "client-secret",
					// width: 300,
				},
			],
			task: "add",
		};
	},
	props: {
		google_search_console: {
			type: Array,
			default: () => [],
		},
		pageLoading: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		connectToGoogle(id) {
			this.$emit("toggle-pageLoading", true);
			this.$store
				.dispatch(GET, { url: `google-connect/${id}` })
				.then(() => {
					this.$emit("refresh", "gsc");
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.$emit("toggle-pageLoading", false);
				});
		},
		submitCredentials() {
			const PAYLOAD = {
				...this.GSC_record,
				id: this.task == "edit" ? this.GSC_record.id : null,
			};
			this.$emit("toggle-pageLoading", true);
			this.$store
				.dispatch(POST, { url: `gsc-credentials`, data: PAYLOAD })
				.then(() => {
					this.$emit("refresh", "gsc");
					this.closeDialog();
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.GSCDialoag = false;
					this.$emit("toggle-pageLoading", false);
				});
		},
		editGSCRecord: function (id) {
			this.task = "edit";
			this.GSCDialoag = true;
			if (id && id > 0) {
				const recordToEdit = this.google_search_console.find((rec) => rec.id == id);
				this.GSC_record = {
					id,
					project_id: recordToEdit.project_id,
					email: recordToEdit.email,
					service_email: recordToEdit.service_email,
					client_secret: recordToEdit.client_secret,
					client_id: recordToEdit.client_id,
				};
			}
		},
		closeDialog: function () {
			this.GSCDialoag = false;
			this.GSC_record = {
				project_id: null,
				private_key_id: null,
				client_email: null,
				client_id: null,
				private_key: null,
				service_email: null,
			};
		},
	},
	computed: {
		GSC_list() {
			return this.google_search_console || [];
		},
	},
	mounted() {},
};
</script>
